import axios from "axios";
export let methodsMixin = {
    methods:{
        // peupler la page par les données dynamiques de pages
         getSectionPage(ulid) {
            axios
                .get(`pages/`+ulid)
                .then((resGetSectionPage) => {
                    this.page = resGetSectionPage.data.sections.sort( (a, b) => {
                        return a.sectionorder - b.sectionorder;
                    });
                    if (this.page.length == 0) {
                        this.page = [
                            {
                                "ulidimages": [],
                                "title": "section vide",
                                "blocks": [
                                    
                                ],
                                "linkedimagesurls": []
                            }
                        ]
                        
                    }
                })
                .catch((errGetSectionPage) => console.log({ errGetSectionPage }));
        },
        // peupler la page par les données de "POSTS/BLOG/ARTICLE"
        getPosts(ulid){
            // solution de secours
            this.page = []
            axios.get(`posts/${ulid}`,{
                headers:{
                    "X-AUTH-TOKEN":localStorage.getItem('token')
                }
            })
            .then(resGetPosts => {
                this.page.push(resGetPosts.data)
            })
            .catch(errGetPosts => console.log(errGetPosts))
        },
         editBanner(event, data, block, section) {
            console.log("ulid section", section);
        },
        
         changePicture(section) {
            console.log("section", section);
            for (let block of section.blocks) {
                block.data.image = this.urlImage;
            }
        },
        
        //change image (meb-biographie)
         changeCurImage(payload,data) {
           data['webp-image-url'] = payload.image
           data['original-image-url'] = payload.originalImage
        },
        //change image (meb-carousel)
         changeImageOriginalWebp(payload, data){
            console.log("data", data);
            console.log("data[payload.element]", data[payload.element]);
            console.log({payload});
            data[payload.element] = {
                "original":payload.image.original,
                "webp":payload.image.image
            }
            // data.image.webp=payload.image
            // data.image.original=payload.originalImage{
                
        },
        
         editCard(event, data, block, section) {
            console.log(section);
        },
        
         carouselNewSlide(event, data) {
            data.slides.push(event);
            //other logic
        },
         carouselMebNewSlide(event, data,block) {
            
            block.data['carousel-meb'].push(event);
            //other logic
        },
        
         NewCardMeb(event, data) {
            data.card.push(event);
        },
        
        //text method ----------------------//
         textRemove(event, data) {
            data.text.splice(data.text.indexOf(event), 1);
            //other logic
        },
        
         textPush(event, data) {
            data.text.splice(event["index"], 0, event["text"]);
            //other logic
        },
        
         ChangeCarrierePr(event, data) {
            // alert("ChangeCarrierePr")
            data[event.element] = event.data
            //other logic
        },
        
         changeTextWithElement(event, data){
            data[event.element] = event.data
        },
        
        
         changeText(event,data){
            data.text = event
        },
        
         textTypographie() {
            // if (data["type"] == "size") {
            //     elem.data["paragraph-font-size"] = data["value"];
            // }
            // if (data["type"] == "justify") {
            //     elem.data["paragraph-font-justify"] = data["value"];
            // }
            // if (data["type"] == "weight") {
            //     elem.data["paragraph-font-weight"] = data["value"];
            // }
            // if (data["type"] == "style") {
            //     elem.data["paragraph-font-style"] = data["value"];
            // }
        },
        //end text method ----------------------//
        
         cardSave(event, data, block, section) {
            let blocks = event['blocks']
            axios
                .put(
                    `sections/${section.ulid}`,
                    {
                        page: this.ulid,
                        blocks,
                    },
                    {
                        headers: {
                            "X-AUTH-TOKEN": localStorage.getItem("token"),
                        },
                    }
                )
                .then((resCardSave) => {
                    console.log({ resCardSave });
                    this.getSectionPage(this.ulid);
                })
                .catch((errCardSave) => console.log({ errCardSave }));
        },
        
         cardProductSave(event, data, block, section) {
            let blocks = event['blocks']
            axios
                .put(
                    `sections/${section.ulid}`,
                    {
                        page: this.ulid,
                        blocks,
                    },
                    {
                        headers: {
                            "X-AUTH-TOKEN": localStorage.getItem("token"),
                        },
                    }
                )
                .then((resCardSave) => {
                    console.log({ resCardSave });
                    this.getSectionPage(this.ulid);
                })
                .catch((errCardSave) => console.log({ errCardSave }));
        },
         savePageEdit() {
            for (let section of this.page) {
                let index = this.page.indexOf(section) + 1;
                console.log(section.blocks);
                if (section.sectionorder == index) {
                    //return;
                    axios
                        .put(
                            `sections/${section.ulid}`,
                            {
                                page: this.ulid,
                                blocks: section.blocks,
                            },
                            {
                                headers: {
                                    "X-AUTH-TOKEN": localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((resPutSection) => console.log(resPutSection))
                        .catch((errPutSection) => console.log(errPutSection));
                } else {
                    axios
                        .put(
                            `sections/${section.ulid}`,
                            {
                                page: this.ulid,
                                sectionorder: "" + (this.page.indexOf(section) + 1) + "",
                                blocks: section.blocks,
                            },
                            {
                                headers: {
                                    "X-AUTH-TOKEN": localStorage.getItem("token"),
                                },
                            }
                        )
                        .then((resPutSection) => console.log(resPutSection))
                        .catch((errPutSection) => console.log(errPutSection));
                }
            }
            this.modeEdit = false;
        },
        
         async deleteSection(event, data, block, section) {
            await this.putPage()
            await this.$swal({
                title: "Êtes-vous sûr?",
                text: "Voulez-vous supprimer ce section?" + section.titre,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, Supprimer",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.value) {
                    axios
                        .delete(`sections/${section.ulid}`, {
                            headers: {
                                "X-AUTH-TOKEN": localStorage.getItem("token"),
                            },
                        })
                        .then((resPutSection) => {
                            this.getSectionPage(this.$route.params.ulid);
                            console.log(resPutSection);
                            this.$swal(
                                "Supprimé!",
                                "Le section a bien été supprimé",
                                "success"
                            );
                        })
                        .catch((errPutSection) => console.log(errPutSection));
                }
            })
        },
        
         changeGrid(blocks, grid) {
            console.log("grid", grid.length)
            console.log("blocks", blocks.length)
            if (blocks.length > grid.length) {
                var data = [...blocks]
                blocks.splice(-(blocks.length - grid.length), (blocks.length - grid.length))
                data.splice(0, (grid.length))
                data.forEach(element => {
                    element.data.forEach(data => {
                        blocks[(blocks.length
                            - 1)].data.push(data)
                    });
                });
            }
            if (blocks.length < grid.length) {
                let pushlength = grid.length - blocks.length;
                let i = 0;
                while (i < pushlength) {
                    blocks.push(
                        {
                            "column": "col",
                            "data": [
        
                            ]
                        }
                    )
                    i++
                }
            }
            let count = 0;
            while (count < grid.length) {
                // console.log('blocks in while',blocks[count])
                // console.log('grid in while',grid[count])
                blocks[count].column = grid[count]
                count++
            }
        },
        
         removeComponent(data, blocks) {
            data.splice(data.indexOf(blocks), 1);
            // console.log({blocks})
        },
        
         loadLog() {
            let logger = JSON.parse(localStorage.getItem('log' + this.ulid));
            let loggerIndex = localStorage.getItem('logindex' + this.ulid)
            let index = 0;
        
            if (loggerIndex) {
                index = loggerIndex
            } else {
                localStorage.setItem('logindex' + this.ulid, index)
            }
        
            if (logger) {
                this.page = logger[index]
            } else {
                let array = []
                array.push(this.page)
                localStorage.setItem('log' + this.ulid, JSON.stringify(array))
                localStorage.setItem('logindex' + this.ulid, index)
            }
        },
        
         saveLog() {
            let logger = JSON.parse(localStorage.getItem('log' + this.ulid));
            let loggerIndex = localStorage.getItem('logindex' + this.ulid)
            let log = logger[loggerIndex]
            // let logstr = JSON.stringify(this.page)
            console.log(JSON.stringify(this.page) === JSON.stringify(log))
        
            //comparaison entre array
            if (loggerIndex == (logger.length - 1)) {
                logger.push(this.page)
                localStorage.setItem('log' + this.ulid, JSON.stringify(logger));
                localStorage.setItem('logindex' + this.ulid, (Number(loggerIndex) + 1))
            }
        },
        
         cancelAutoUpdate() {
            clearInterval(this.timer);
        },
        
         changeLog(operation) {
            let loggerIndex = localStorage.getItem('logindex' + this.ulid)
            if (operation == "z") {
                localStorage.setItem('logindex' + this.ulid, (Number(loggerIndex) - 1))
            }
            if (operation == "y") {
                localStorage.setItem('logindex' + this.ulid, (Number(loggerIndex) + 1))
            }
            this.loadLog()
        },
        
        // Horizontal-banner
         changeTextHorizontalBanner(event,data) {
            data.text=event
        }
    }
}