<template>
    <section>
        <!-- <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="goToEditPostContent()">Modifier le contenu de l'article</button>
        </div> -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h1 class="text-center">{{page.title}}</h1>
                        <Content v-if="ulid" :ulid="ulid" :editable="false" page_name="blog" />
                        <p class="text-right text-muted" v-if="page.datepublished">
                            publié le : {{new Date(page.datepublished).toLocaleString('fr-FR')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Commentaires</h4>
                        <!-- my postcomments -->
                        <div class="add-items d-lg-flex">
                            <input v-model="myComments" type="text" class="form-control todo-list-input" placeholder="Qu'en pensez-vous?">
                            <button @click="postmycomment()"
                             class="add btn btn-gradient-primary font-weight-bold todo-list-add-btn ml-0 ml-lg-2 mt-2 mt-lg-0" id="mypostcomment">
                                <i class="mdi mdi-send"></i>
                            </button>
                        </div>

                        <div class="profile-feed">
                            <div v-if="page && postcomments && postcomments.length==0">Il n'y a pas de commentaire</div>
                            <div class="d-flex align-items-start profile-feed-item" v-for="postcomment in postcomments" :key="postcomment.id">
                                <img src="../../../../assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/>
                                <div class="ml-4">
                                <h6>
                                   {{postcomment.user.lastname}} {{postcomment.user.firstname}}
                                   <span v-if="postcomment.user.ulid ==user_ulid">
                                       <small class="ml-4 text-muted" v-b-modal="'modal-edit'" @click="edit.myCommentsToEdit =postcomment.comment,edit.ulidCommentsToEdit=postcomment.ulid"><i class="mdi mdi-pen mr-1 pointer"></i></small>
                                       <small class="ml-4 text-muted" @click="deletemypostcomment(postcomment.ulid)"><i class="mdi mdi-delete mr-1 pointer"></i></small>
                                   </span>
                                </h6>
                                <p>
                                    {{postcomment.comment}}
                                </p>
                                </div>
                            </div>
                        </div>

                        <!-- modal edit -->
                        <b-modal id="modal-edit" title="Modifier votre commentaire" hide-footer>
                            <div class="add-items d-lg-flex">
                                <input v-model="edit.myCommentsToEdit" type="text" class="form-control todo-list-input" placeholder="Qu'en pensez-vous?">
                                <button @click="editmycomment(edit.myCommentsToEdit, edit.ulidCommentsToEdit)"
                                class="add btn btn-gradient-primary font-weight-bold todo-list-add-btn ml-0 ml-lg-2 mt-2 mt-lg-0" id="mypostcomment">
                                    <i class="mdi mdi-send"></i>
                                </button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import { methodsMixin } from '../preview/mixins/methodsMixin'
import Content from '../preview/page-preview-d.vue'
export default {
    name:"postDetails",
    components:{Content},
    mixins:[methodsMixin],
    data(){
        return{
            // postcomments:[1,2,3],
            ulid:"",
            myComments:[],
            page:'',
            postcomments:'',
            mypostcomment:'',
            mypostcommentslist:'',
            edit:{
                ulidCommentsToEdit:'',
                myCommentsToEdit:''
            }
        }
    },
    computed:{
        user_ulid(){
            return localStorage.getItem('user_ulid')
        }
    },
    methods:{
        goToEditPostContent(){
            this.$router.push({
                name:"page-preview",
                params:{
                    ulid:this.ulid,
                    page:"blog"
                }
            })
        },
        getPosts(ulid){
            axios.get(`posts/${ulid}`,{
                withCredentials:true,
                headers:{
                    "X-AUTH-TOKEN":localStorage.getItem('token')
                }
            })
            .then(resGetPosts => {
                this.page = resGetPosts.data
                this.postcomments = resGetPosts.data.postcomments
            })
            .catch(errGetPosts => console.log(errGetPosts))
        },
        postmycomment(){
            axios.post(`mypostcomments`,
            {   
                post:this.ulid,
                comment:this.myComments,
                datepublished:new Date(),
                datemodified: new Date()
            },
            {
                headers:{
                    "X-AUTH-TOKEN":localStorage.getItem('token')
                }
            })
            .then(resPostMyComment => {
                console.log(resPostMyComment)
                this.myComments=''
                this.getPosts(this.ulid)
            })
            .catch(errPostMyComment => console.error(errPostMyComment))
        },
        editmycomment(comment, ulid){
            axios.put(`mypostcomments/${ulid}`,{
                // post: ulid,  
                comment: comment
            },{
                headers:{
                    "X-AUTH-TOKEN": localStorage.getItem('token')
                }
            })
            .then(resEdit => {
                resEdit
                this.getPosts(this.ulid)
                this.$bvModal.hide('modal-edit')
            })
            .catch(errEdit => {
                console.log({errEdit});
            })
        },
        deletemypostcomment(ulid){
            axios.delete(`mypostcomments/${ulid}`,{
                headers:{
                    "X-AUTH-TOKEN":localStorage.getItem('token')
                }
            })
            .then(resdelete =>{
                console.log(resdelete);
                this.getPosts(this.ulid)
            })
            .catch(errdelete=> console.error(errdelete))
        }
    },
    created(){
        this.ulid = this.$route.params.ulid
    },
    async mounted(){
        this.getPosts(this.ulid)
    }
}
</script>